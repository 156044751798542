/* Container for the text */
.text-container {
    position: relative;
    display: inline-block;
    
  }
  
  /* The yellow line */
  .text-container::before {
    content: '';
    position: absolute;
    top: 0; /* Position the line at the top */
    left: 0; /* Align the line with the start of the text */
    width: calc(1ch * 2.5); /* Width of the first 3 letters */
    height: 4px; /* Height of the line */
    background-color: #ffc629; /* Color of the line */
    margin-bottom: 5;
  }
  
  /* The text */
  .text-container span {
    position: relative;
  }


/* App.css */

.wrap-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.wrap-item {
  background-color: lightgray;
  padding: 20px;
  text-align: center;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .wrap-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wrap-item {
    flex: 1;
  }
}

html {
  scroll-behavior: smooth;
}

.textStretch {
  font-stretch: condensed;
  max-lines: 2;
  width: "100%";
}